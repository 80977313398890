

/**
 * INT8 to Binary-Array Conversion Table
 * 
 * We pre-generate the conversion of n:int8 to x:bool[] for all possible values,
 * because the JS approach `Number(n >>> 0).toString(2)` doesn't seem to explicitly
 * consider the big vs little endian problem.
 * 
 * It is more important to ensure that conversion is consistent rather than fast
 * or elegant programmed. Thus, we pre-generat the conversion table based on the 
 * python functions used for data pre-preprocessing in the backend.
 * 
 * Generate JSON from python script
```py
import numpy as np
from typing import List

def int8_to_bool(serialized: List[np.int8]) -> List[bool]:
  return np.unpackbits(
    serialized.astype(np.uint8),
    bitorder='big').reshape(-1)

for n in np.arange(-128, 128):
  print(f"{int8_to_bool(np.array([n], np.int8)).tolist()},")
```
 * 
 * The first entry tblInt8toBin[0] is for n=-128, the last entry tblInt8toBin[255] is for n=127.
 * Thus, we need to add 128 to the index to get the correct value.
 * 
 * ```js
 * let hash = tblInt8toBin(Number.parseInt(128. + Number(n)))
 * ```
 */
const tblInt8toBin = [
  [1, 0, 0, 0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0, 0, 0, 1],
  [1, 0, 0, 0, 0, 0, 1, 0],
  [1, 0, 0, 0, 0, 0, 1, 1],
  [1, 0, 0, 0, 0, 1, 0, 0],
  [1, 0, 0, 0, 0, 1, 0, 1],
  [1, 0, 0, 0, 0, 1, 1, 0],
  [1, 0, 0, 0, 0, 1, 1, 1],
  [1, 0, 0, 0, 1, 0, 0, 0],
  [1, 0, 0, 0, 1, 0, 0, 1],
  [1, 0, 0, 0, 1, 0, 1, 0],
  [1, 0, 0, 0, 1, 0, 1, 1],
  [1, 0, 0, 0, 1, 1, 0, 0],
  [1, 0, 0, 0, 1, 1, 0, 1],
  [1, 0, 0, 0, 1, 1, 1, 0],
  [1, 0, 0, 0, 1, 1, 1, 1],
  [1, 0, 0, 1, 0, 0, 0, 0],
  [1, 0, 0, 1, 0, 0, 0, 1],
  [1, 0, 0, 1, 0, 0, 1, 0],
  [1, 0, 0, 1, 0, 0, 1, 1],
  [1, 0, 0, 1, 0, 1, 0, 0],
  [1, 0, 0, 1, 0, 1, 0, 1],
  [1, 0, 0, 1, 0, 1, 1, 0],
  [1, 0, 0, 1, 0, 1, 1, 1],
  [1, 0, 0, 1, 1, 0, 0, 0],
  [1, 0, 0, 1, 1, 0, 0, 1],
  [1, 0, 0, 1, 1, 0, 1, 0],
  [1, 0, 0, 1, 1, 0, 1, 1],
  [1, 0, 0, 1, 1, 1, 0, 0],
  [1, 0, 0, 1, 1, 1, 0, 1],
  [1, 0, 0, 1, 1, 1, 1, 0],
  [1, 0, 0, 1, 1, 1, 1, 1],
  [1, 0, 1, 0, 0, 0, 0, 0],
  [1, 0, 1, 0, 0, 0, 0, 1],
  [1, 0, 1, 0, 0, 0, 1, 0],
  [1, 0, 1, 0, 0, 0, 1, 1],
  [1, 0, 1, 0, 0, 1, 0, 0],
  [1, 0, 1, 0, 0, 1, 0, 1],
  [1, 0, 1, 0, 0, 1, 1, 0],
  [1, 0, 1, 0, 0, 1, 1, 1],
  [1, 0, 1, 0, 1, 0, 0, 0],
  [1, 0, 1, 0, 1, 0, 0, 1],
  [1, 0, 1, 0, 1, 0, 1, 0],
  [1, 0, 1, 0, 1, 0, 1, 1],
  [1, 0, 1, 0, 1, 1, 0, 0],
  [1, 0, 1, 0, 1, 1, 0, 1],
  [1, 0, 1, 0, 1, 1, 1, 0],
  [1, 0, 1, 0, 1, 1, 1, 1],
  [1, 0, 1, 1, 0, 0, 0, 0],
  [1, 0, 1, 1, 0, 0, 0, 1],
  [1, 0, 1, 1, 0, 0, 1, 0],
  [1, 0, 1, 1, 0, 0, 1, 1],
  [1, 0, 1, 1, 0, 1, 0, 0],
  [1, 0, 1, 1, 0, 1, 0, 1],
  [1, 0, 1, 1, 0, 1, 1, 0],
  [1, 0, 1, 1, 0, 1, 1, 1],
  [1, 0, 1, 1, 1, 0, 0, 0],
  [1, 0, 1, 1, 1, 0, 0, 1],
  [1, 0, 1, 1, 1, 0, 1, 0],
  [1, 0, 1, 1, 1, 0, 1, 1],
  [1, 0, 1, 1, 1, 1, 0, 0],
  [1, 0, 1, 1, 1, 1, 0, 1],
  [1, 0, 1, 1, 1, 1, 1, 0],
  [1, 0, 1, 1, 1, 1, 1, 1],
  [1, 1, 0, 0, 0, 0, 0, 0],
  [1, 1, 0, 0, 0, 0, 0, 1],
  [1, 1, 0, 0, 0, 0, 1, 0],
  [1, 1, 0, 0, 0, 0, 1, 1],
  [1, 1, 0, 0, 0, 1, 0, 0],
  [1, 1, 0, 0, 0, 1, 0, 1],
  [1, 1, 0, 0, 0, 1, 1, 0],
  [1, 1, 0, 0, 0, 1, 1, 1],
  [1, 1, 0, 0, 1, 0, 0, 0],
  [1, 1, 0, 0, 1, 0, 0, 1],
  [1, 1, 0, 0, 1, 0, 1, 0],
  [1, 1, 0, 0, 1, 0, 1, 1],
  [1, 1, 0, 0, 1, 1, 0, 0],
  [1, 1, 0, 0, 1, 1, 0, 1],
  [1, 1, 0, 0, 1, 1, 1, 0],
  [1, 1, 0, 0, 1, 1, 1, 1],
  [1, 1, 0, 1, 0, 0, 0, 0],
  [1, 1, 0, 1, 0, 0, 0, 1],
  [1, 1, 0, 1, 0, 0, 1, 0],
  [1, 1, 0, 1, 0, 0, 1, 1],
  [1, 1, 0, 1, 0, 1, 0, 0],
  [1, 1, 0, 1, 0, 1, 0, 1],
  [1, 1, 0, 1, 0, 1, 1, 0],
  [1, 1, 0, 1, 0, 1, 1, 1],
  [1, 1, 0, 1, 1, 0, 0, 0],
  [1, 1, 0, 1, 1, 0, 0, 1],
  [1, 1, 0, 1, 1, 0, 1, 0],
  [1, 1, 0, 1, 1, 0, 1, 1],
  [1, 1, 0, 1, 1, 1, 0, 0],
  [1, 1, 0, 1, 1, 1, 0, 1],
  [1, 1, 0, 1, 1, 1, 1, 0],
  [1, 1, 0, 1, 1, 1, 1, 1],
  [1, 1, 1, 0, 0, 0, 0, 0],
  [1, 1, 1, 0, 0, 0, 0, 1],
  [1, 1, 1, 0, 0, 0, 1, 0],
  [1, 1, 1, 0, 0, 0, 1, 1],
  [1, 1, 1, 0, 0, 1, 0, 0],
  [1, 1, 1, 0, 0, 1, 0, 1],
  [1, 1, 1, 0, 0, 1, 1, 0],
  [1, 1, 1, 0, 0, 1, 1, 1],
  [1, 1, 1, 0, 1, 0, 0, 0],
  [1, 1, 1, 0, 1, 0, 0, 1],
  [1, 1, 1, 0, 1, 0, 1, 0],
  [1, 1, 1, 0, 1, 0, 1, 1],
  [1, 1, 1, 0, 1, 1, 0, 0],
  [1, 1, 1, 0, 1, 1, 0, 1],
  [1, 1, 1, 0, 1, 1, 1, 0],
  [1, 1, 1, 0, 1, 1, 1, 1],
  [1, 1, 1, 1, 0, 0, 0, 0],
  [1, 1, 1, 1, 0, 0, 0, 1],
  [1, 1, 1, 1, 0, 0, 1, 0],
  [1, 1, 1, 1, 0, 0, 1, 1],
  [1, 1, 1, 1, 0, 1, 0, 0],
  [1, 1, 1, 1, 0, 1, 0, 1],
  [1, 1, 1, 1, 0, 1, 1, 0],
  [1, 1, 1, 1, 0, 1, 1, 1],
  [1, 1, 1, 1, 1, 0, 0, 0],
  [1, 1, 1, 1, 1, 0, 0, 1],
  [1, 1, 1, 1, 1, 0, 1, 0],
  [1, 1, 1, 1, 1, 0, 1, 1],
  [1, 1, 1, 1, 1, 1, 0, 0],
  [1, 1, 1, 1, 1, 1, 0, 1],
  [1, 1, 1, 1, 1, 1, 1, 0],
  [1, 1, 1, 1, 1, 1, 1, 1],
  [0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1],
  [0, 0, 0, 0, 0, 0, 1, 0],
  [0, 0, 0, 0, 0, 0, 1, 1],
  [0, 0, 0, 0, 0, 1, 0, 0],
  [0, 0, 0, 0, 0, 1, 0, 1],
  [0, 0, 0, 0, 0, 1, 1, 0],
  [0, 0, 0, 0, 0, 1, 1, 1],
  [0, 0, 0, 0, 1, 0, 0, 0],
  [0, 0, 0, 0, 1, 0, 0, 1],
  [0, 0, 0, 0, 1, 0, 1, 0],
  [0, 0, 0, 0, 1, 0, 1, 1],
  [0, 0, 0, 0, 1, 1, 0, 0],
  [0, 0, 0, 0, 1, 1, 0, 1],
  [0, 0, 0, 0, 1, 1, 1, 0],
  [0, 0, 0, 0, 1, 1, 1, 1],
  [0, 0, 0, 1, 0, 0, 0, 0],
  [0, 0, 0, 1, 0, 0, 0, 1],
  [0, 0, 0, 1, 0, 0, 1, 0],
  [0, 0, 0, 1, 0, 0, 1, 1],
  [0, 0, 0, 1, 0, 1, 0, 0],
  [0, 0, 0, 1, 0, 1, 0, 1],
  [0, 0, 0, 1, 0, 1, 1, 0],
  [0, 0, 0, 1, 0, 1, 1, 1],
  [0, 0, 0, 1, 1, 0, 0, 0],
  [0, 0, 0, 1, 1, 0, 0, 1],
  [0, 0, 0, 1, 1, 0, 1, 0],
  [0, 0, 0, 1, 1, 0, 1, 1],
  [0, 0, 0, 1, 1, 1, 0, 0],
  [0, 0, 0, 1, 1, 1, 0, 1],
  [0, 0, 0, 1, 1, 1, 1, 0],
  [0, 0, 0, 1, 1, 1, 1, 1],
  [0, 0, 1, 0, 0, 0, 0, 0],
  [0, 0, 1, 0, 0, 0, 0, 1],
  [0, 0, 1, 0, 0, 0, 1, 0],
  [0, 0, 1, 0, 0, 0, 1, 1],
  [0, 0, 1, 0, 0, 1, 0, 0],
  [0, 0, 1, 0, 0, 1, 0, 1],
  [0, 0, 1, 0, 0, 1, 1, 0],
  [0, 0, 1, 0, 0, 1, 1, 1],
  [0, 0, 1, 0, 1, 0, 0, 0],
  [0, 0, 1, 0, 1, 0, 0, 1],
  [0, 0, 1, 0, 1, 0, 1, 0],
  [0, 0, 1, 0, 1, 0, 1, 1],
  [0, 0, 1, 0, 1, 1, 0, 0],
  [0, 0, 1, 0, 1, 1, 0, 1],
  [0, 0, 1, 0, 1, 1, 1, 0],
  [0, 0, 1, 0, 1, 1, 1, 1],
  [0, 0, 1, 1, 0, 0, 0, 0],
  [0, 0, 1, 1, 0, 0, 0, 1],
  [0, 0, 1, 1, 0, 0, 1, 0],
  [0, 0, 1, 1, 0, 0, 1, 1],
  [0, 0, 1, 1, 0, 1, 0, 0],
  [0, 0, 1, 1, 0, 1, 0, 1],
  [0, 0, 1, 1, 0, 1, 1, 0],
  [0, 0, 1, 1, 0, 1, 1, 1],
  [0, 0, 1, 1, 1, 0, 0, 0],
  [0, 0, 1, 1, 1, 0, 0, 1],
  [0, 0, 1, 1, 1, 0, 1, 0],
  [0, 0, 1, 1, 1, 0, 1, 1],
  [0, 0, 1, 1, 1, 1, 0, 0],
  [0, 0, 1, 1, 1, 1, 0, 1],
  [0, 0, 1, 1, 1, 1, 1, 0],
  [0, 0, 1, 1, 1, 1, 1, 1],
  [0, 1, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0, 0, 1],
  [0, 1, 0, 0, 0, 0, 1, 0],
  [0, 1, 0, 0, 0, 0, 1, 1],
  [0, 1, 0, 0, 0, 1, 0, 0],
  [0, 1, 0, 0, 0, 1, 0, 1],
  [0, 1, 0, 0, 0, 1, 1, 0],
  [0, 1, 0, 0, 0, 1, 1, 1],
  [0, 1, 0, 0, 1, 0, 0, 0],
  [0, 1, 0, 0, 1, 0, 0, 1],
  [0, 1, 0, 0, 1, 0, 1, 0],
  [0, 1, 0, 0, 1, 0, 1, 1],
  [0, 1, 0, 0, 1, 1, 0, 0],
  [0, 1, 0, 0, 1, 1, 0, 1],
  [0, 1, 0, 0, 1, 1, 1, 0],
  [0, 1, 0, 0, 1, 1, 1, 1],
  [0, 1, 0, 1, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 1],
  [0, 1, 0, 1, 0, 0, 1, 0],
  [0, 1, 0, 1, 0, 0, 1, 1],
  [0, 1, 0, 1, 0, 1, 0, 0],
  [0, 1, 0, 1, 0, 1, 0, 1],
  [0, 1, 0, 1, 0, 1, 1, 0],
  [0, 1, 0, 1, 0, 1, 1, 1],
  [0, 1, 0, 1, 1, 0, 0, 0],
  [0, 1, 0, 1, 1, 0, 0, 1],
  [0, 1, 0, 1, 1, 0, 1, 0],
  [0, 1, 0, 1, 1, 0, 1, 1],
  [0, 1, 0, 1, 1, 1, 0, 0],
  [0, 1, 0, 1, 1, 1, 0, 1],
  [0, 1, 0, 1, 1, 1, 1, 0],
  [0, 1, 0, 1, 1, 1, 1, 1],
  [0, 1, 1, 0, 0, 0, 0, 0],
  [0, 1, 1, 0, 0, 0, 0, 1],
  [0, 1, 1, 0, 0, 0, 1, 0],
  [0, 1, 1, 0, 0, 0, 1, 1],
  [0, 1, 1, 0, 0, 1, 0, 0],
  [0, 1, 1, 0, 0, 1, 0, 1],
  [0, 1, 1, 0, 0, 1, 1, 0],
  [0, 1, 1, 0, 0, 1, 1, 1],
  [0, 1, 1, 0, 1, 0, 0, 0],
  [0, 1, 1, 0, 1, 0, 0, 1],
  [0, 1, 1, 0, 1, 0, 1, 0],
  [0, 1, 1, 0, 1, 0, 1, 1],
  [0, 1, 1, 0, 1, 1, 0, 0],
  [0, 1, 1, 0, 1, 1, 0, 1],
  [0, 1, 1, 0, 1, 1, 1, 0],
  [0, 1, 1, 0, 1, 1, 1, 1],
  [0, 1, 1, 1, 0, 0, 0, 0],
  [0, 1, 1, 1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0, 0, 1, 0],
  [0, 1, 1, 1, 0, 0, 1, 1],
  [0, 1, 1, 1, 0, 1, 0, 0],
  [0, 1, 1, 1, 0, 1, 0, 1],
  [0, 1, 1, 1, 0, 1, 1, 0],
  [0, 1, 1, 1, 0, 1, 1, 1],
  [0, 1, 1, 1, 1, 0, 0, 0],
  [0, 1, 1, 1, 1, 0, 0, 1],
  [0, 1, 1, 1, 1, 0, 1, 0],
  [0, 1, 1, 1, 1, 0, 1, 1],
  [0, 1, 1, 1, 1, 1, 0, 0],
  [0, 1, 1, 1, 1, 1, 0, 1],
  [0, 1, 1, 1, 1, 1, 1, 0],
  [0, 1, 1, 1, 1, 1, 1, 1]
]


/** 
 * Decode Vector<Int8> to Vector<Bool>
 * 
 * @param {*} serialized One serialized example
 * @returns One decoded example
 */
const int8_to_bool = (serialized) => {
  let hashvalues = []
  serialized.forEach((n) => {
    hashvalues.push(tblInt8toBin[ Number.parseInt(128. + Number(n)) ])
  })
  return hashvalues.flat()
}


/** 
 * Scale by 1st column value
 * 
 * @param {*} example One example
 * @returns One scaled example
 */
const divide_by_1st_col = (example) => {
  let denom = example[0]
  let metrics = new Array(example.length - 1);
  for(let i = 1; i < example.length; i++) {
    metrics[i - 1] = example[i] / denom
  }
  return metrics
}


/** 
 * Scale by sum
 * 
 * @param {*} example One example
 * @returns One scaled example
 */
const divide_by_sum = (example) => {
  let denom = example.reduce((a, b) => a + b, 0)
  let metrics = new Array(example.length);
  for(let i = 0; i < example.length; i++) {
    metrics[i] = example[i] / denom
  }
  return metrics
}


/** 
 * Scale each Int8 number to [-1, 1]
 * 
 * @param {*} example One example
 * @returns One scaled example
*/
const int8_to_scaledfloat = (example) => {
  let newex = new Array(example.length)
  for(let i = 0; i < example.length; i++) {
    newex[i] = 1.0 - (Math.min(127., Math.max(-128., example[i])) + 128.0) / 255.0
  }
  return newex
}


/** 
 * One plus Log
 * 
 * @param {*} example One example
 * @returns One transformed example
 */
const one_plus_log = (example)  => {
  let newex = new Array(example.length)
  for(let i = 0; i < example.length; i++) {
    newex[i] = Math.log(1. + Math.max(0., example[i]))
  }
  return newex
}


// https://github.com/satzbeleg/evidence-features/tree/main#features-overview

const int2float = (
    feats1, feats2, feats3, feats4,
    feats5, feats6, feats7, feats8,
    feats9, feats12, feats13, feats14
  ) => {
  return Array.prototype.concat(
    int8_to_bool(feats1),  // sbert
    divide_by_1st_col(feats2),  // trankit: UPOS (16)
    divide_by_1st_col(feats3),  // trankit: feats (47)
    divide_by_sum(feats4),  // trankit/nodedist (21)
    divide_by_1st_col(feats5),  // consonant (3)
    divide_by_1st_col(feats6),  // char (6)
    divide_by_1st_col(feats7),  // bigram (10)
    divide_by_1st_col(feats8),  // cow (6)
    divide_by_1st_col(feats9),  // smor (14)
    one_plus_log(feats12),  // seqlen (2)
    int8_to_scaledfloat(feats13),   // fasttext176 langdetect (10)
    divide_by_1st_col(feats14)   // emoji (22)
  );
}


// export default int2float;
export { int2float, int8_to_bool };
